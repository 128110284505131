<template>
  <div id="app">
    <Works_Header />
    <Works_Tabs />
  </div>
</template>

<script>
import Works_Header from './components/Works_Header';
import Works_Tabs from './components/Works_Tabs';
export default {
  name: 'App',
  components: {
    Works_Header,
    Works_Tabs
  }
}
</script>

<style></style>








