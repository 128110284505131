<template>
  <div>
    <div class="Tabs">
      <ul class="Tabs_ul">
        <li v-for="(tab, index) in tabs" :key="index" @click="setActiveIndex(index)"
          :class="{ active: activeIndex === index }">{{ tab.title }}</li>
      </ul>
    </div>

    <div v-for="(tab, index) in tabs" :key="index" v-show="activeIndex === index" class="Tabs_border">
      <div v-for="(content, contentIndex) in tab.contents" :key="contentIndex" class="Tabs_show">
        <ul class="Tabs_img">
          <li>
            <img :src="content.image" alt="图片" @click="showQRCode(content.code)">
            <div class="Menu_images">
              <template v-if="tab.title === '全部行业'">
                {{ content.text }}
              </template>
              <template v-else>
                {{ tab.text }}
              </template>
            </div>
            {{ content.content }}
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showQRCodeModal" class="QRCode_modal" @click="hideQRCode">
      <div class="QRCode_content">
        <img :src="currentQRCodeImage" alt="二维码">
        <p class="hint_txt">{{ hint }}</p>
      </div>
    </div>
    <p class="more-txt" @click="showService">{{ more }}</p>
    <div v-if="showQRCodeSer" class="QRCode_modal" @click="hideQRCode">
      <img src="../assets/images/用户案例/service.png" alt="客服" style="width: 150px;">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          title: '全部行业', contents: [
            { content: '52购物', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/52购物.png'), code: require('@/assets/images/用户案例/综合商城/52购物-1.png') },
            { content: 'BEE MALL全球购', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/BEE MALL全球购.png'), code: require('@/assets/images/用户案例/综合商城/BEE MALL全球购-1.png') },
            { content: '倍琢科技', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/倍琢科技.png'), code: require('@/assets/images/用户案例/综合商城/倍琢科技-1.png') },
            { content: '大三村中业爱民', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/大三村中业爱民.png'), code: require('@/assets/images/用户案例/综合商城/大三村中业爱民-1.png') },
            { content: '聚惠洋品', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/聚惠洋品.png'), code: require('@/assets/images/用户案例/综合商城/聚惠洋品-1.png') },
            { content: '绿叶GO', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/绿叶GO.png'), code: require('@/assets/images/用户案例/综合商城/绿叶GO-1.png') },
            { content: '拾光礼遇', text: '综合商城', image: require('@/assets/images/用户案例/综合商城/拾光礼遇.png'), code: require('@/assets/images/用户案例/综合商城/拾光礼遇-1.png') },
            { content: '德艺堂白茶', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/德艺堂白茶.png'), code: require('@/assets/images/用户案例/食品茶饮/德艺堂白茶-1.png') },
            { content: '鼎宁茶府', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/鼎宁茶府.png'), code: require('@/assets/images/用户案例/食品茶饮/鼎宁茶府-1.png') },
            { content: '官妃燕', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/官妃燕.png'), code: require('@/assets/images/用户案例/食品茶饮/官妃燕-1.png') },
            { content: '贵州茅台集团', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/贵州茅台集团.png'), code: require('@/assets/images/用户案例/食品茶饮/贵州茅台集团-1.png') },
            { content: '湖南特产', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/湖南特产.png'), code: require('@/assets/images/用户案例/食品茶饮/湖南特产-1.png') },
            { content: '千道湾', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/千道湾.png'), code: require('@/assets/images/用户案例/食品茶饮/千道湾-1.png') },
            { content: '青兰香泽', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/青兰香泽.png'), code: require('@/assets/images/用户案例/食品茶饮/青兰香泽-1.png') },
            { content: '裕隆记', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/裕隆记.png'), code: require('@/assets/images/用户案例/食品茶饮/裕隆记-1.png') },
            { content: '正悟堂', text: '食品茶饮', image: require('@/assets/images/用户案例/食品茶饮/正悟堂.png'), code: require('@/assets/images/用户案例/食品茶饮/正悟堂-1.png') },
            { content: '澳斯布朗', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/澳斯布朗.png'), code: require('@/assets/images/用户案例/家居装饰/澳斯布朗-1.png') },
            { content: '德优成家居', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/德优成家居.png'), code: require('@/assets/images/用户案例/家居装饰/德优成家居-1.png') },
            { content: '多宝城', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/多宝城.png'), code: require('@/assets/images/用户案例/家居装饰/多宝城-1.png') },
            { content: '弗兰特灯饰', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/弗兰特灯饰.png'), code: require('@/assets/images/用户案例/家居装饰/弗兰特灯饰-1.png') },
            { content: '美灯严选', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/美灯严选.png'), code: require('@/assets/images/用户案例/家居装饰/美灯严选-1.png') },
            { content: '致远', text: '家居装饰', image: require('@/assets/images/用户案例/家居装饰/致远.png'), code: require('@/assets/images/用户案例/家居装饰/致远-1.png') },
            { content: '爱情路潮品旗舰店', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/爱情路潮品旗舰店.png'), code: require('@/assets/images/用户案例/美妆服饰/爱情路潮品旗舰店-1.png') },
            { content: '白领商城', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/白领商城.png'), code: require('@/assets/images/用户案例/美妆服饰/白领商城-1.png') },
            { content: '构_云定制', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/构_云定制.png'), code: require('@/assets/images/用户案例/美妆服饰/构_云定制-1.png') },
            { content: '美兮皮肤门诊部', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/美兮皮肤门诊部.png'), code: require('@/assets/images/用户案例/美妆服饰/美兮皮肤门诊部-1.png') },
            { content: '美莹嘉', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/美莹嘉.png'), code: require('@/assets/images/用户案例/美妆服饰/美莹嘉-1.png') },
            { content: '美妆季', text: '美妆服饰', image: require('@/assets/images/用户案例/美妆服饰/美妆季.png'), code: require('@/assets/images/用户案例/美妆服饰/美妆季-1.png') },
            { content: 'MSQ梦斯绮产后修复', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/MSQ梦斯绮产后修复.png'), code: require('@/assets/images/用户案例/母婴护理/MSQ梦斯绮产后修复-1.png') },
            { content: '宝贝港HKBB', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/宝贝港HKBB.png'), code: require('@/assets/images/用户案例/母婴护理/宝贝港HKBB-1.png') },
            { content: '彩虹鸭母婴用品', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/彩虹鸭母婴用品.png'), code: require('@/assets/images/用户案例/母婴护理/彩虹鸭母婴用品-1.png') },
            { content: '哆邦宝妈', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/哆邦宝妈.png'), code: require('@/assets/images/用户案例/母婴护理/哆邦宝妈-1.png') },
            { content: '俊贝母婴', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/俊贝母婴.png'), code: require('@/assets/images/用户案例/母婴护理/俊贝母婴-1.png') },
            { content: '美奥口腔', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/美奥口腔.png'), code: require('@/assets/images/用户案例/母婴护理/美奥口腔-1.png') },
            { content: '水云禾', text: '母婴护理', image: require('@/assets/images/用户案例/母婴护理/水云禾.png'), code: require('@/assets/images/用户案例/母婴护理/水云禾-1.png') },
            { content: '官妃燕', text: '蛋糕烘焙', image: require('@/assets/images/用户案例/蛋糕烘焙/官妃燕-2.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/官妃燕-2-2.png') },
            { content: '好店', text: '蛋糕烘焙', image: require('@/assets/images/用户案例/蛋糕烘焙/好店.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/好店-1.png') },
            { content: '麦小玥', text: '蛋糕烘焙', image: require('@/assets/images/用户案例/蛋糕烘焙/麦小玥.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/麦小玥-1.png') },
            { content: '年少有你', text: '蛋糕烘焙', image: require('@/assets/images/用户案例/蛋糕烘焙/年少有你.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/年少有你-1.png') },
            { content: '幸福西饼', text: '蛋糕烘焙', image: require('@/assets/images/用户案例/蛋糕烘焙/幸福西饼.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/幸福西饼-1.png') },
            { content: '菜新优选配送', text: '生鲜蔬果', image: require('@/assets/images/用户案例/生鲜蔬果/菜新优选配送.png'), code: require('@/assets/images/用户案例/生鲜蔬果/菜新优选配送-1.png') },
            { content: '大园家大闸蟹', text: '生鲜蔬果', image: require('@/assets/images/用户案例/生鲜蔬果/大园家大闸蟹.png'), code: require('@/assets/images/用户案例/生鲜蔬果/大园家大闸蟹-1.png') },
            { content: '皇家贡蟹', text: '生鲜蔬果', image: require('@/assets/images/用户案例/生鲜蔬果/皇家贡蟹.png'), code: require('@/assets/images/用户案例/生鲜蔬果/皇家贡蟹-1.png') },
            { content: '司灿果蔬', text: '生鲜蔬果', image: require('@/assets/images/用户案例/生鲜蔬果/司灿果蔬.png'), code: require('@/assets/images/用户案例/生鲜蔬果/司灿果蔬-1.png') },
            { content: '草原淘豆豆', text: '其他', image: require('@/assets/images/用户案例/其他/草原淘豆豆.png'), code: require('@/assets/images/用户案例/其他/草原淘豆豆-1.png') },
            { content: '鼎极教育', text: '其他', image: require('@/assets/images/用户案例/其他/鼎极教育.png'), code: require('@/assets/images/用户案例/其他/鼎极教育-1.png') },
            { content: '简壹婚礼', text: '其他', image: require('@/assets/images/用户案例/其他/简壹婚礼.png'), code: require('@/assets/images/用户案例/其他/简壹婚礼-1.png') },
            { content: '联合营养中心', text: '其他', image: require('@/assets/images/用户案例/其他/联合营养中心.png'), code: require('@/assets/images/用户案例/其他/联合营养中心-1.png') }
          ]
        },
        {
          title: '综合商城',
          text: '综合商城',
          contents: [
            { content: '52购物', image: require('@/assets/images/用户案例/综合商城/52购物.png'), code: require('@/assets/images/用户案例/综合商城/52购物-1.png') },
            { content: 'BEE MALL全球购', image: require('@/assets/images/用户案例/综合商城/BEE MALL全球购.png'), code: require('@/assets/images/用户案例/综合商城/BEE MALL全球购-1.png') },
            { content: '倍琢科技', image: require('@/assets/images/用户案例/综合商城/倍琢科技.png'), code: require('@/assets/images/用户案例/综合商城/倍琢科技-1.png') },
            { content: '大三村中业爱民', image: require('@/assets/images/用户案例/综合商城/大三村中业爱民.png'), code: require('@/assets/images/用户案例/综合商城/大三村中业爱民-1.png') },
            { content: '聚惠洋品', image: require('@/assets/images/用户案例/综合商城/聚惠洋品.png'), code: require('@/assets/images/用户案例/综合商城/聚惠洋品-1.png') },
            { content: '绿叶GO', image: require('@/assets/images/用户案例/综合商城/绿叶GO.png'), code: require('@/assets/images/用户案例/综合商城/绿叶GO-1.png') },
            { content: '拾光礼遇', image: require('@/assets/images/用户案例/综合商城/拾光礼遇.png'), code: require('@/assets/images/用户案例/综合商城/拾光礼遇-1.png') }
          ]
        },
        {
          title: '食品茶饮',
          text: '食品茶饮',
          contents: [
            { content: '德艺堂白茶', image: require('@/assets/images/用户案例/食品茶饮/德艺堂白茶.png'), code: require('@/assets/images/用户案例/食品茶饮/德艺堂白茶-1.png') },
            { content: '鼎宁茶府', image: require('@/assets/images/用户案例/食品茶饮/鼎宁茶府.png'), code: require('@/assets/images/用户案例/食品茶饮/鼎宁茶府-1.png') },
            { content: '官妃燕', image: require('@/assets/images/用户案例/食品茶饮/官妃燕.png'), code: require('@/assets/images/用户案例/食品茶饮/官妃燕-1.png') },
            { content: '贵州茅台集团', image: require('@/assets/images/用户案例/食品茶饮/贵州茅台集团.png'), code: require('@/assets/images/用户案例/食品茶饮/贵州茅台集团-1.png') },
            { content: '湖南特产', image: require('@/assets/images/用户案例/食品茶饮/湖南特产.png'), code: require('@/assets/images/用户案例/食品茶饮/湖南特产-1.png') },
            { content: '千道湾', image: require('@/assets/images/用户案例/食品茶饮/千道湾.png'), code: require('@/assets/images/用户案例/食品茶饮/千道湾-1.png') },
            { content: '青兰香泽', image: require('@/assets/images/用户案例/食品茶饮/青兰香泽.png'), code: require('@/assets/images/用户案例/食品茶饮/青兰香泽-1.png') },
            { content: '裕隆记', image: require('@/assets/images/用户案例/食品茶饮/裕隆记.png'), code: require('@/assets/images/用户案例/食品茶饮/裕隆记-1.png') },
            { content: '正悟堂', image: require('@/assets/images/用户案例/食品茶饮/正悟堂.png'), code: require('@/assets/images/用户案例/食品茶饮/正悟堂-1.png') }
          ]
        },
        {
          title: '家居装饰',
          text: '家居装饰',
          contents: [
            { content: '澳斯布朗', image: require('@/assets/images/用户案例/家居装饰/澳斯布朗.png'), code: require('@/assets/images/用户案例/家居装饰/澳斯布朗-1.png') },
            { content: '德优成家居', image: require('@/assets/images/用户案例/家居装饰/德优成家居.png'), code: require('@/assets/images/用户案例/家居装饰/德优成家居-1.png') },
            { content: '多宝城', image: require('@/assets/images/用户案例/家居装饰/多宝城.png'), code: require('@/assets/images/用户案例/家居装饰/多宝城-1.png') },
            { content: '弗兰特灯饰', image: require('@/assets/images/用户案例/家居装饰/弗兰特灯饰.png'), code: require('@/assets/images/用户案例/家居装饰/弗兰特灯饰-1.png') },
            { content: '美灯严选', image: require('@/assets/images/用户案例/家居装饰/美灯严选.png'), code: require('@/assets/images/用户案例/家居装饰/美灯严选-1.png') },
            { content: '致远', image: require('@/assets/images/用户案例/家居装饰/致远.png'), code: require('@/assets/images/用户案例/家居装饰/致远-1.png') }

          ]
        },
        {
          title: '美妆服饰',
          text: '美妆服饰',
          contents: [
            { content: '爱情路潮品旗舰店', image: require('@/assets/images/用户案例/美妆服饰/爱情路潮品旗舰店.png'), code: require('@/assets/images/用户案例/美妆服饰/爱情路潮品旗舰店-1.png') },
            { content: '白领商城', image: require('@/assets/images/用户案例/美妆服饰/白领商城.png'), code: require('@/assets/images/用户案例/美妆服饰/白领商城-1.png') },
            { content: '构_云定制', image: require('@/assets/images/用户案例/美妆服饰/构_云定制.png'), code: require('@/assets/images/用户案例/美妆服饰/构_云定制-1.png') },
            { content: '美兮皮肤门诊部', image: require('@/assets/images/用户案例/美妆服饰/美兮皮肤门诊部.png'), code: require('@/assets/images/用户案例/美妆服饰/美兮皮肤门诊部-1.png') },
            { content: '美莹嘉', image: require('@/assets/images/用户案例/美妆服饰/美莹嘉.png'), code: require('@/assets/images/用户案例/美妆服饰/美莹嘉-1.png') },
            { content: '美妆季', image: require('@/assets/images/用户案例/美妆服饰/美妆季.png'), code: require('@/assets/images/用户案例/美妆服饰/美妆季-1.png') }
          ]
        },
        {
          title: '母婴护理',
          text: '母婴护理',
          contents: [
            { content: 'MSQ梦斯绮产后修复', image: require('@/assets/images/用户案例/母婴护理/MSQ梦斯绮产后修复.png'), code: require('@/assets/images/用户案例/母婴护理/MSQ梦斯绮产后修复-1.png') },
            { content: '宝贝港HKBB', image: require('@/assets/images/用户案例/母婴护理/宝贝港HKBB.png'), code: require('@/assets/images/用户案例/母婴护理/宝贝港HKBB-1.png') },
            { content: '彩虹鸭母婴用品', image: require('@/assets/images/用户案例/母婴护理/彩虹鸭母婴用品.png'), code: require('@/assets/images/用户案例/母婴护理/彩虹鸭母婴用品-1.png') },
            { content: '哆邦宝妈', image: require('@/assets/images/用户案例/母婴护理/哆邦宝妈.png'), code: require('@/assets/images/用户案例/母婴护理/哆邦宝妈-1.png') },
            { content: '俊贝母婴', image: require('@/assets/images/用户案例/母婴护理/俊贝母婴.png'), code: require('@/assets/images/用户案例/母婴护理/俊贝母婴-1.png') },
            { content: '美奥口腔', image: require('@/assets/images/用户案例/母婴护理/美奥口腔.png'), code: require('@/assets/images/用户案例/母婴护理/美奥口腔-1.png') },
            { content: '水云禾', image: require('@/assets/images/用户案例/母婴护理/水云禾.png'), code: require('@/assets/images/用户案例/母婴护理/水云禾-1.png') }
          ]
        },
        {
          title: '蛋糕烘焙',
          text: '蛋糕烘焙',
          contents: [
            { content: '官妃燕', image: require('@/assets/images/用户案例/蛋糕烘焙/官妃燕-2.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/官妃燕-2-2.png') },
            { content: '好店', image: require('@/assets/images/用户案例/蛋糕烘焙/好店.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/好店-1.png') },
            { content: '麦小玥', image: require('@/assets/images/用户案例/蛋糕烘焙/麦小玥.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/麦小玥-1.png') },
            { content: '年少有你', image: require('@/assets/images/用户案例/蛋糕烘焙/年少有你.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/年少有你-1.png') },
            { content: '幸福西饼', image: require('@/assets/images/用户案例/蛋糕烘焙/幸福西饼.png'), code: require('@/assets/images/用户案例/蛋糕烘焙/幸福西饼-1.png') }
          ]
        },
        {
          title: '生鲜蔬果',
          text: '生鲜蔬果',
          contents: [
            { content: '菜新优选配送', image: require('@/assets/images/用户案例/生鲜蔬果/菜新优选配送.png'), code: require('@/assets/images/用户案例/生鲜蔬果/菜新优选配送-1.png') },
            { content: '大园家大闸蟹', image: require('@/assets/images/用户案例/生鲜蔬果/大园家大闸蟹.png'), code: require('@/assets/images/用户案例/生鲜蔬果/大园家大闸蟹-1.png') },
            { content: '皇家贡蟹', image: require('@/assets/images/用户案例/生鲜蔬果/皇家贡蟹.png'), code: require('@/assets/images/用户案例/生鲜蔬果/皇家贡蟹-1.png') },
            { content: '司灿果蔬', image: require('@/assets/images/用户案例/生鲜蔬果/司灿果蔬.png'), code: require('@/assets/images/用户案例/生鲜蔬果/司灿果蔬-1.png') }
          ]
        },
        {
          title: '其他',
          text: '其他',
          contents: [
            { content: '草原淘豆豆', image: require('@/assets/images/用户案例/其他/草原淘豆豆.png'), code: require('@/assets/images/用户案例/其他/草原淘豆豆-1.png') },
            { content: '鼎极教育', image: require('@/assets/images/用户案例/其他/鼎极教育.png'), code: require('@/assets/images/用户案例/其他/鼎极教育-1.png') },
            { content: '简壹婚礼', image: require('@/assets/images/用户案例/其他/简壹婚礼.png'), code: require('@/assets/images/用户案例/其他/简壹婚礼-1.png') },
            { content: '联合营养中心', image: require('@/assets/images/用户案例/其他/联合营养中心.png'), code: require('@/assets/images/用户案例/其他/联合营养中心-1.png') }
          ]
        },
      ],
      activeIndex: 0,
      // showAllTabs: false
      showQRCodeModal: false,
      currentQRCodeImage: '',
      hint: '长按识别或保存二维码',
      more: '添加客服咨询更多案例\n>',
      showQRCodeSer: false
    };
  },
  // created() {
  //   this.showAllTabs = true;
  // },
  methods: {
    setActiveIndex(index) {
      // if (index === 0) {
      //   this.showAllTabs = true;
      // } else {
      //   this.showAllTabs = false;
      // }
      this.activeIndex = index
    },
    showQRCode(code) {
      this.currentQRCodeImage = code;
      this.showQRCodeModal = true;
    },
    hideQRCode() {
      this.showQRCodeModal = false;
      this.showQRCodeSer = false;
    },
    showService() {
      this.showQRCodeSer = true;
    }
  }
};
</script>

<style>
.Tabs {
  margin-top: 0.4rem;
}

.Tabs_ul li {
  float: left;
  list-style: none;
  font-size: 15px;
  letter-spacing: 1.2px;
  color: gray;
  text-align: center;
  width: 111px;
  height: 25px;
  line-height: 25px;
  margin: 0 0 0.23rem 0.3rem;
  position: relative;
}

.Tabs_ul .active {
  background-color: rgb(246, 248, 249);
  color: rgb(58, 151, 249);
  border-radius: 8px 8px 8px 8px;
}

.Tabs_img li {
  float: left;
  list-style: none;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  text-align: center;
  margin: 0.4rem 0rem 0.7rem 0.29rem;
  width: 81px;
  height: 80px;
}

.Tabs_img img {
  width: 80px;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 3px;
}

.Tabs_border {
  float: left;
  width: 370px;
  margin-left: 2px;
}

.QRCode_modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0%;
  color: white;
}

.QRCode_content img {
  width: 130px;
}

.hint_txt {
  text-align: center;
  line-height: 3;
  font-size: 12.5px;
}

.more-txt {
  font-size: 15px;
  position: relative;
  float: left;
  top: 18px;
  left: 31%;
}

.Menu_images {
  height: 20px;
  width: 80px;
  position: absolute;
  margin-top: -26.2px;
  margin-left: 0.5px;
  background-color: rgb(0, 0, 0, 0.3);
  border-bottom-left-radius: 8.4px;
  border-bottom-right-radius: 8.4px;
  color: white;
  line-height: 22px;
}
</style>