<template>
  <div>
    <img src="../assets/Header.png">
  </div>
</template>

<script>
export default {

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}
</style>
